<template>
  <section id="ft-about-3" class="ft-about-section-3">
    <div class="container">
      <div class="ft-about-content-3">
        <div class="row">
          <div
            class="col-lg-4 wow fadeInLeft"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <div class="ft-about-img-wrapper-3 position-relative">
              <div class="ft-about-img-3">
                <img :src="getItem(image)" alt="" />
              </div> 
            </div>
          </div>
          <div class="col-lg-8">
            <div class="ft-about-text-3">
              <div
                class="ft-section-title-3 headline wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <span class="text-uppercase"
                  >experience and varied experience in logistic industry</span
                >
                <h2>{{ name }}</h2>
              </div>
              <div
                class="ft-about-sub-text wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                {{ desc }}
              </div>
              <div
                class="ft-about-sub-text wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                {{ desc2 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import data from "../../data/data.json";
export default {
  data() {
    return {
      mainData: "",
      routeName: "",
      name: "",
      image: "",
      desc: "",
      desc2: ""
    };
  },
  created() {
    this.mainData = data.directors.team;

    // for Get Route Name
    this.routeName = this.$route.name;

    // if(this.routeName == "Ajay Nair"){
    //     this.name = this.mainData[0].name
    //     this.image = this.mainData[0].profile
    //     this.desc = this.mainData[0].about[0].row
    //     this.desc2 = this.mainData[0].about[1].row
    // } 
    if(this.routeName == "Edwin Alexander"){
        this.name = this.mainData[0].name
        this.image = this.mainData[0].profile
        this.desc = this.mainData[0].about[0].row
        this.desc2 = this.mainData[0].about[1].row
    } else if(this.routeName == "Moncy George"){
        this.name = this.mainData[1].name
        this.image = this.mainData[1].profile
        this.desc = this.mainData[1].about[0].row
        this.desc2 = this.mainData[1].about[1].row
    } else if (this.routeName == "Niraj Israni"){
        this.name = this.mainData[2].name
        this.image = this.mainData[2].profile
        this.desc = this.mainData[2].about[0].row
        this.desc2 = this.mainData[2].about[1].row
    } else{
        this.name = ""
        this.image = ""
        this.desc = ""
    }
  },
  methods:{
    getItem(pic){
      if(pic) {
        return require("../../../public/assets/imgs/team/" + pic)
      } else {
        return null
      }
    }
  }
};
</script>
