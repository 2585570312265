<template>
    <breadCrumb/>
    <teamDesc/>
</template>

<script>
import breadCrumb from '../components/topSection/topSection.vue'
import teamDesc from '../components/team/teamDesc.vue'
export default {
    components: {
        breadCrumb, 
        teamDesc
    },
    data(){
        return{}
    }
}
</script>